var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',[_c('md-card',[_c('md-card-header',{staticClass:"md-card-header-icon"}),_c('md-card-content',[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('md-field',{class:[
          { 'md-valid': !_vm.errors.has('region_key') }, { 'md-error': _vm.errors.has('region_key') }
        ]},[_c('label',[_vm._v(_vm._s(_vm.$t('road_network.region_key')))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.region_key),expression:"modelValidations.region_key"}],attrs:{"type":"text","data-vv-name":"region_key","required":""},model:{value:(_vm.region_key),callback:function ($$v) {_vm.region_key=$$v},expression:"region_key"}})],1),_c('md-field',{class:[
          { 'md-valid': !_vm.errors.has('region_description') }, { 'md-error': _vm.errors.has('region_description') }
        ]},[_c('label',[_vm._v(_vm._s(_vm.$t('stdCols.name_en')))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.region_description),expression:"modelValidations.region_description"}],attrs:{"type":"text","data-vv-name":"region_description","required":""},model:{value:(_vm.region_description),callback:function ($$v) {_vm.region_description=$$v},expression:"region_description"}})],1),_c('md-field',{class:[
          { 'md-valid': !_vm.errors.has('region_description_en') }, { 'md-error': _vm.errors.has('region_description_en') }
        ]},[_c('label',[_vm._v(_vm._s(_vm.$t('stdCols.name_ru')))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.region_description_en),expression:"modelValidations.region_description_en"}],attrs:{"type":"text","data-vv-name":"region_description_en","required":""},model:{value:(_vm.region_description_en),callback:function ($$v) {_vm.region_description_en=$$v},expression:"region_description_en"}})],1),_c('md-field',{class:[
          { 'md-valid': !_vm.errors.has('regional_dep') }, { 'md-error': _vm.errors.has('regional_dep') }
        ]},[_c('label',[_vm._v(_vm._s(_vm.$t('road_network.regional_office') + ' (RU)'))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.regional_dep),expression:"modelValidations.regional_dep"}],attrs:{"type":"text","data-vv-name":"regional_dep","required":""},model:{value:(_vm.regional_dep),callback:function ($$v) {_vm.regional_dep=$$v},expression:"regional_dep"}})],1),_c('md-field',{class:[
          { 'md-valid': !_vm.errors.has('regional_dep_en') }, { 'md-error': _vm.errors.has('regional_dep_en') }
        ]},[_c('label',[_vm._v(_vm._s(_vm.$t('road_network.regional_office') + ' (EN)'))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.regional_dep_en),expression:"modelValidations.regional_dep_en"}],attrs:{"type":"text","data-vv-name":"regional_dep_en","required":""},model:{value:(_vm.regional_dep_en),callback:function ($$v) {_vm.regional_dep_en=$$v},expression:"regional_dep_en"}})],1),_c('md-field',{staticStyle:{"width":"50px","top":"-7px"}},[_c('md-switch',{attrs:{"id":"active"},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_vm._v(_vm._s(_vm.$t('road_network.active')))])],1)],1),_c('div',{staticClass:"md-layout md-size-50"},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"}),_c('div',{staticClass:"md-layout-item md-size-100 text-right"},[_c('md-button',{staticClass:"md-success",attrs:{"native-type":"submit","disabled":_vm.saveBtnDisabled},nativeOn:{"click":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.save'))+" ")]),_c('md-button',{staticClass:"md-accent",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onClose.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.cancel'))+" ")])],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }