<!--
File: RegionEditForm.vue
Description: form for adding/editing a single region.
-->
<template>
  <form>
    <md-card>
      <md-card-header class="md-card-header-icon">
      </md-card-header>
      <md-card-content>
        <div class="md-layout-item md-small-size-100 md-size-50">
          <md-field :class="[
            { 'md-valid': !errors.has('region_key') }, { 'md-error': errors.has('region_key') }
          ]">
            <label>{{ $t('road_network.region_key') }}</label>
            <md-input v-model="region_key" type="text" data-vv-name="region_key" required
              v-validate="modelValidations.region_key"></md-input>
          </md-field>

          <md-field :class="[
            { 'md-valid': !errors.has('region_description') }, { 'md-error': errors.has('region_description') }
          ]">
            <label>{{ $t('stdCols.name_en') }}</label>
            <md-input v-model="region_description" type="text" data-vv-name="region_description" required
              v-validate="modelValidations.region_description"></md-input>
          </md-field>
          <md-field :class="[
            { 'md-valid': !errors.has('region_description_en') }, { 'md-error': errors.has('region_description_en') }
          ]">
            <label>{{ $t('stdCols.name_ru') }}</label>
            <md-input v-model="region_description_en" type="text" data-vv-name="region_description_en" required
              v-validate="modelValidations.region_description_en"></md-input>
          </md-field>

          <md-field :class="[
            { 'md-valid': !errors.has('regional_dep') }, { 'md-error': errors.has('regional_dep') }
          ]">
            <label>{{ $t('road_network.regional_office') + ' (RU)' }}</label>
            <md-input v-model="regional_dep" type="text" data-vv-name="regional_dep" required
              v-validate="modelValidations.regional_dep"></md-input>
          </md-field>
          <md-field :class="[
            { 'md-valid': !errors.has('regional_dep_en') }, { 'md-error': errors.has('regional_dep_en') }
          ]">
            <label>{{ $t('road_network.regional_office') + ' (EN)' }}</label>
            <md-input v-model="regional_dep_en" type="text" data-vv-name="regional_dep_en" required
              v-validate="modelValidations.regional_dep_en"></md-input>
          </md-field>
          <md-field style="width: 50px; top: -7px">
            <md-switch v-model="active" id="active">{{ $t('road_network.active') }}</md-switch>
          </md-field>
        </div>

        <div class="md-layout md-size-50">
          <div class="md-layout-item md-small-size-100 md-size-50">
          </div>
          <div class="md-layout-item md-size-100 text-right">
            <md-button class="md-success" native-type="submit" @click.native.prevent="validate"
              :disabled="saveBtnDisabled">
              {{ $t('buttons.save') }}
            </md-button>
            <md-button class="md-accent" @click.stop.prevent="onClose">
              {{ $t('buttons.cancel') }}
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
  import Swal from 'sweetalert2'

  export default {
    name: 'region-edit-form',
    props: {
      oper: String
    },
    data() {
      return {
        region_id: null,
        fk_rn: null,
        region_key: null,
        region_description: null,
        region_description_en: null,
        regional_dep_en: null,
        regional_dep: null,
        active: null,
        modelValidations: {
          //fk_rn: { required: true, },   // min: 3  },
          region_key: { required: true, min: 3 },
          region_description: { required: true, min: 3 },
          region_description_en: { required: true, min: 3 },
          regional_dep_en: { required: true, },
          regional_dep: { required: true, min: 3 },
        }
      }
    },
    created() {
      const { rid = null } = this.$route.params
      this.region_id = rid
      if (this.oper === 'upd' && rid) {
        this.$store.dispatch('LOAD_RDB_REGION_BY_ID', rid).then((res) => {
          const theRegion = Array.isArray(res) ? res[0] : res
          this.fk_rn = theRegion.fk_rn
          this.region_key = theRegion.region_key
          this.region_description = theRegion.region_description
          this.region_description_en = theRegion.region_description_en
          this.regional_dep = theRegion.regional_dep
          this.regional_dep_en = theRegion.regional_dep_en
          this.active = theRegion.active
        })
      }
    },
    methods: {
      onClose() {
        this.$store.dispatch('SET_USE_HISTORY')
        this.$router.push('/inventory_data/regions')
      },
      async validate() {
        this.$validator.validateAll().then((isValid) => {
          if (isValid) {
            const theRegion = {
              //fk_rn: this.fk_rn,
              fk_rn: 1,
              region_key: this.region_key,
              region_description: this.region_description,
              region_description_en: this.region_description_en,
              regional_dep: this.regional_dep,
              regional_dep_en: this.regional_dep_en,
              active: this.active
            }

            const alert = {
              type: 'success',
              text: this.$t(`road_network.region`) + this.$t(`label.was_saved`, {
                name_ru: this.region_description,
                name_en: this.region_description_en
              }),
              footer: ''
            }

            const reqData = this.oper === 'add' ? theRegion : { id: this.region_id, theRegion }
            this.$store.dispatch(`${this.oper.toUpperCase()}_RDB_REGION`, reqData).then(
              () => {
                Swal.fire(alert).then(() => {
                  this.$nextTick(() => this.$validator.reset())
                })
              },
              (err) => {
                alert.type = 'error'
                alert.text = this.$t(`road_network.region`) + this.$t(`label.was_not_saved`, {
                  name_ru: this.region_description,
                  name_en: this.region_description_en
                })
                alert.footer = err
                Swal.fire(alert)
              }
            )
          }
        })
        this.onClose()
      }
    },

    computed: {
      saveBtnDisabled() {
        return this.errors.items.length > 0
      }
    }
  }
</script>
<style>
.md-card {
  margin: 0px 0;
}

.md-button+.md-button {
  margin-left: 10px;
}
</style>
  